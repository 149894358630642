// LanguageSelection.jsx
import React from 'react';
import './language-selection.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaCheck } from 'react-icons/fa'; // Importing Font Awesome check icon
import { AccountService } from "../../../../services/account.service";
import { emitter } from '../../function/event-emitter/event-emitter'; 
import { addParamsToURL, isValidLanguage } from '../../../../utils.js';
import { withRouter } from 'react-router-dom'; // Import withRouter

class LanguageSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: 'en', // Default language code
            isLogin: false, // Initialize isLogin to false
        };
        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
    }

    componentDidMount() {
        const isLogin = AccountService.userValue ? true : false;
        const urlParams = new URLSearchParams(this.props.location.search);
        const urlLang = urlParams.get('lang');

        let lang = 'en';

        if (urlLang && isValidLanguage(urlLang)) {
            lang = urlLang;
        } else {
            if (isLogin) {
                lang = AccountService.userValue.data.language;
            } else {
                lang = localStorage.getItem('selectedLanguage') || 'en';
            }
            addParamsToURL(this, { lang });
        }
        localStorage.setItem('selectedLanguage', lang);
        this.setState({ selectedLanguage: lang, isLogin }, () => {
            emitter.emit('languageChange', lang);
        });
    }

    async handleSelectLanguage(lang, event) {
        event.preventDefault(); // Prevent default behavior if necessary

        // Validate the selected language
        const validatedLanguage = isValidLanguage(lang) ? lang : 'en';

        // Update the state with the validated language
        this.setState({ selectedLanguage: validatedLanguage }, async () => {
            localStorage.setItem('selectedLanguage', validatedLanguage);

            if (this.state.isLogin) {
                try {
                    await AccountService.set_language(validatedLanguage);
                } catch (error) {
                    console.error('Error updating language on server:', error);
                    // Optionally, revert the language selection or notify the user
                }
            }
            // Update the URL with the selected language parameter
            addParamsToURL(this, { lang: validatedLanguage });

            // Emit languageChange event to notify other components
            emitter.emit('languageChange', validatedLanguage);
        });
    }

    getLanguageName(code) {
        const languages = {
            en: 'English',
            es: 'Español',
        };
        return languages[code] || 'Language';
    }

    render() {
        const { selectedLanguage } = this.state;

        const languages = {
            en: 'English',
            es: 'Español',
        };

        return (
            <div>
                <NavDropdown 
                    title={this.getLanguageName(selectedLanguage)} 
                    menuVariant="dark"
                    onSelect={this.handleSelectLanguage}
                    className="basic-nav-dropdown"
                >
                    {Object.entries(languages).map(([code, name]) => (
                        <NavDropdown.Item key={code} eventKey={code}>
                            {selectedLanguage === code && <FaCheck style={{ marginRight: '8px' }} />}
                            {name}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            </div>
        );
    }
}

export default withRouter(LanguageSelection);
