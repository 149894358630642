// voiceLists.js
export const voiceLists = {
    en: [
        { code: 'en-US-Standard-B', name: 'US-Male' },
        { code: 'en-US-Standard-C', name: 'US-Female' },
        { code: 'en-GB-Standard-B', name: 'GB-Male' },
        { code: 'en-GB-Standard-A', name: 'GB-Female' },
        { code: 'en-AU-Standard-B', name: 'AU-Male' },
        { code: 'en-AU-Standard-A', name: 'AU-Female' },
    ],
    es: [
        { code: 'es-US-Studio-B', name: 'ES-Masculina' },
        { code: 'es-ES-Neural2-A', name: 'ES-Femenina' },
    ],
};
