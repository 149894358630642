// VoicePopover.jsx
import React from 'react';
import './select-voice-button.css';
import { emitter } from '../../function/event-emitter/event-emitter'; // Ensure the correct path
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCheck } from 'react-icons/fa';
import { voiceLists } from './voiceLists';
import { AccountService } from '../../../../services/account.service';

export default class VoicePopover extends React.Component {
    constructor(props) {
        super(props);
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
        const voiceConfig = sessionStorage.getItem('voiceConfig') || JSON.stringify({"en": "en-US-Standard-B", "es": "es-US-Studio-B"});
        this.state = {
            currentLanguage: savedLanguage,
            voiceConfig: JSON.parse(voiceConfig),
            voiceName: voiceConfig[savedLanguage],
            availableVoices: voiceLists[savedLanguage] || [],
        };
        this.handleSelectVoice = this.handleSelectVoice.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    componentDidMount() {
        const isLogin = AccountService.userValue ? true : false;
        if (isLogin) {
            const voice_config = AccountService.userValue.data.voice_config;
            const lang = AccountService.userValue.data.language;
            sessionStorage.setItem('voiceConfig', voice_config)
            sessionStorage.setItem('selectedLanguage', lang)
        }
        emitter.on('languageChange', this.handleLanguageChange);
    }

    componentWillUnmount() {
        emitter.off('languageChange', this.handleLanguageChange);
    }

    handleLanguageChange(newLanguage) {
        const availableVoices = voiceLists[newLanguage] || [];
        const current_voice = this.state.voiceName;
        const newVoice = this.state.voiceConfig[newLanguage]

        this.setState({
            currentLanguage: newLanguage,
            availableVoices: availableVoices,
            voiceName: newVoice,
        });
        if (current_voice !== newVoice) {
            emitter.emit('voiceChange', newVoice);
        }
    }

    handleSelectVoice(code, event) {
        event.preventDefault();
        this.setState({ voiceName: code });
        AccountService.set_voice(this.state.currentLanguage,code)
        const current_voice_config = this.state.voiceConfig;
        current_voice_config[this.state.currentLanguage] = code;
        sessionStorage.setItem("voiceConfig", JSON.stringify(current_voice_config))
        this.setState({
            voiceConfig: current_voice_config
        })
        emitter.emit('voiceChange', code);
    }

    render() {
        const { availableVoices, voiceName } = this.state;

        if (!availableVoices.length) {
            return <div>No voices available for the selected language.</div>;
        }

        return (
            <>
                {availableVoices.map(({ code, name }) => (
                    <Dropdown.Item 
                        href="#" 
                        key={code} 
                        onClick={(event) => this.handleSelectVoice(code, event)}
                    >
                        {voiceName === code && <FaCheck style={{ marginRight: '8px' }} />}
                        {name}
                    </Dropdown.Item>
                ))}
            </>
        );
    }
}
