import React from 'react';
import {withRouter} from 'react-router-dom';
import TopicService from '../../services/topics.service.js';
import {__debug, extract_verse_info} from '../../utils.js';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader.js"
import {connect} from 'react-redux';
import TextToSpeech from '../../services/text-to-speech.js';
import {emitter} from '../common/function/event-emitter/event-emitter.jsx';
import {AccountService} from "../../services/account.service.js";
import './verse-card.css';

class TopicDetailSearchResult extends React.Component {
    constructor(props) {
        super(props)
        this.bibleLinkMenuRef = React.createRef()
        this.popupRefs = {}
        this.props.START_LOADING()
        const lang = localStorage.getItem('selectedLanguage') || 'en';
        const verseId =  this.props.match.params.verseId
        this.state = {
            verseName: '',
            audioContent: null,
            auDisplay: false,
            rawtext: '',
            timeOnPage: 0,
            createBookmarkError : false,
            lang,
            verse : {},
            verseId : verseId,
            verseData : {}
        };
        this.retrieveTopicDetailByVerseName = this.retrieveTopicDetailByVerseName.bind(this);
        this.handleOnclickReadMore = this.handleOnclickReadMore.bind(this);
        this.handleOnclickReadIcon = this.handleOnclickReadIcon.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    async componentDidMount() {
        const verseData = await TopicService.getTopicDetailByName(this.state.verseId);
        const isLogin = AccountService.userValue ? true : false;
        let lang = "en"
        if (isLogin) {
            const voice_config = AccountService.userValue.data.voice_config;
            lang = AccountService.userValue.data.language;
            sessionStorage.setItem('voiceConfig', voice_config)
            sessionStorage.setItem('selectedLanguage', lang)
        }
        this.setState({
            verseData,
            verse : this.format_verse(verseData['data']['verse_info'][0],lang)
            })

        emitter.on('voiceChange', voice_name => {
            this.reloadAudio(this.state.rawtext, voice_name)
        });
        emitter.on('languageChange', this.handleLanguageChange);
       

        this.timer = setInterval(() => {
            this.setState((prevState) => ({timeOnPage: prevState.timeOnPage + 5}));
        }, 5000);

        document.addEventListener("mousedown", this.handleClickOutside);
        this.props.STOP_LOADING();
    }

    async componentWillUnmount() {
        emitter.off('voiceChange');
        emitter.off('languageChange', this.handleLanguageChange);
        clearInterval(this.timer);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleLanguageChange(lang) {
        let verseData = this.state.verseData || {}
        if (verseData) {
            let verse = this.format_verse(verseData['data']['verse_info'][0],lang)
            this.setState({verse,lang})
        } else {
             this.setState({lang})
        }
    }

    async retrieveTopicDetailByVerseName(currentVerseInfo) {
        await TopicService.getTopicDetailByName(currentVerseInfo)
            .then((response) => {
                const verseList_data = response.data?.verse_info || []
                const lang = this.state.lang || 'en'
                let verseList = this.createVerseList(verseList_data,lang)
                this.setState({
                    verseList,
                    verseList_data
                });
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }

    format_verse(item,lang) {
        let new_item
        if (item["verse_string_valid"] && item["verse_found"]) {
            let display_text = item['verse_raw_text'][lang];
            let read_text = display_text;
            new_item = {...item,display_text,read_text}
        }
        return new_item
    }

    handleOnclickReadMore = (verse_id_string) => {
        const extract_result = extract_verse_info(verse_id_string);
        if (extract_result) {
            this.props.history.push({
                pathname: `/chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`,
            });
        } else {
            this.props.history.push({
                pathname: `/chapter-overview/${encodeURI(verse_id_string)}`,
            });
        }
    };

    handleOnclickReadIcon = async (text) => {
        const lang = localStorage.getItem('selectedLanguage') || 'en';
        const voiceConfigText = sessionStorage.getItem('voiceConfig') || JSON.stringify({"en": "en-US-Standard-B", "es": "es-US-Studio-B"});
        const voiceConfig = JSON.parse(voiceConfigText)
        const voice = voiceConfig[lang]
        this.setState({
            auDisplay: true
        })
        if (this.state.voice !== voice) {
            this.reloadAudio(text, voice);
        } else {
            if (text !== this.state.rawtext) {
                this.reloadAudio(text, voice);
            } else {
                const audioElement = document.getElementById('myAudio');
                if (audioElement.paused) {
                    audioElement.play();
                } else {
                    audioElement.pause();
                }
            }
        }
    }

    reloadAudio = (text, voice) => {
        if (voice && text) {
            setTimeout(async () => {
                this.setState({
                    voice: voice
                })
                const audioContent = await TextToSpeech.makespeech(text, voice)
                this.setState({
                    audioContent: audioContent, auDisplay: true, rawtext: text,
                });
            }, 200)
        }
    }

    cancelAudio = () => {
        this.setState({
            auDisplay: false,
        })
    }

    render() {
        const { 
            verse,
            audioContent, 
        } = this.state;
        const auDisplay = this.state.auDisplay;
        return (
        <div className='topic-detail-page'>
            <Helmet>
                <title> View Details for a Topic | ScriptureCast®</title>
            </Helmet>
            <div className='subject-list-content'>
                <div className="topic-audio">
                    {auDisplay && 
                        <div>
                            <audio id="myAudio" src={'data:audio/mp3;base64,' + audioContent} controls autoPlay/>
                        </div>
                    }
                </div>
                <div className="bible-link-menu" ref={this.bibleLinkMenuRef}>
                    <div>
                        {verse['display_text'] && 
                            <div className="verse-card verse-font">
                            <div className="text-center">– {verse['verse_id_string']} –</div>
                            <div>{verse['display_text']}</div>
                            <div className="readmore-box">
                                <div className="button" onClick={() => {
                                        this.handleOnclickReadMore(verse['verse_id_string'])
                                    }}>
                    Read More of {verse['doc_name']} {verse['chapter_number']}
                </div>

                <div className="volume-up button" onClick={() => {
                                        this.handleOnclickReadIcon(verse['read_text'])
                                    }}>
                    <i className="fa fa-volume-up"></i>
                </div>
                
            </div>
        </div>
                                
                                }
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(TopicDetailSearchResult));