import React from 'react';
import './user-menu.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutCustomDropdownItem from './logout-custom-dropdown-item/logout-custom-dropdown-item';
import CustomDropdownItem from '../help-menu/custom-dropdown-item/custom-dropdown-item';
import {AccountService} from '../../../../services/account.service';
import LockIcon from '@mui/icons-material/Lock';
import PaymentsIcon from '@mui/icons-material/Payments';
import {SearchHistory} from './search-history';
import Bookmark from './bookmark';


export class UserMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            firstName: null,
            showDropdown: false,
            showHistory: false,
        }
    }

    componentDidMount() {
        const user = AccountService.userValue;
        if (user) {
            this.setState({
                user : user,
                firstName: user?.data.firstName,
            })
        }
    }


    handleTitleClick() {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    }

    handleHistory() {
        this.setState({showHistory: true})
    }

    handleCloseModal() {
        this.setState({showHistory: false})

    }


    render() {
        const {firstName, showHistory,user} = this.state;


        return (
            <>
            {user && <Bookmark/>}
            <div>
                {firstName && (
                    <>
                        <NavDropdown
                            title={`Hi ${firstName}`}
                            menuvariant="dark"
                            className="basic-nav-dropdown"
                        >
                            <CustomDropdownItem
                                toPath="/account/change-password"
                                textHelp="Change Password"
                                iconHelp={<LockIcon></LockIcon>}
                            ></CustomDropdownItem>

                            <NavDropdown.Divider/>

                            {/* <NavDropdown.Item id="history-item" as="button" onClick={()=>this.handleHistory()}>
            <p id="history-text"><BsClockHistory className="clock"/>History</p>          
            </NavDropdown.Item>
            
            <NavDropdown.Divider/> */}


                            <CustomDropdownItem
                                toPath="/donate/subscription"
                                textHelp="Manage Monthly Donation"
                                iconHelp={<PaymentsIcon></PaymentsIcon>}
                            ></CustomDropdownItem>
                            <NavDropdown.Divider></NavDropdown.Divider>

                            <LogoutCustomDropdownItem
                                textHelp="Log out"
                                iconHelp={<LogoutIcon></LogoutIcon>}
                            ></LogoutCustomDropdownItem>
                        </NavDropdown>

                        <SearchHistory showHistory={showHistory} handleCloseModal={() => this.handleCloseModal()}/>

                    </>
                )}
            </div>
             </>
        );
    }
}

