import React from 'react';
import './help-menu.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AssistantIcon from '@mui/icons-material/Assistant';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PostCastPageIcon from '@mui/icons-material/Podcasts';
import VoicePopover from '../select-voice-button/select-voice-button';
import {
    BsFillPeopleFill,
    BsFillFileEarmarkLockFill,
    BsFillFileEarmarkMedicalFill,
    BsFillClipboardDataFill
} from "react-icons/bs";

import Dropdown from 'react-bootstrap/Dropdown';

export default class HelpMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {

        return (
            <div>
                <NavDropdown className="basic-nav-dropdown" title="Help Menu" menualign="right" menuvariant="dark">
                    <Dropdown.Item id="drop-item" href="/">
                        <span className="icon-help"> <HomeIcon/></span>
                        <span className="text-help"> Home Page</span>
                    </Dropdown.Item>
                    <Dropdown.Item id="drop-item" href="/about/how-to-use">
                        <span className="icon-help"> <HelpCenterIcon/></span>
                        <span className="text-help"> How to use</span>
                    </Dropdown.Item>
                    <Dropdown.Item id="drop-item" href="/podcasts">
                        <span className="icon-help"> <PostCastPageIcon/></span>
                        <span className="text-help"> The Shepherd and The Shrink Podcast</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/assistant-on-journey">
                        <span className="icon-help"> <AssistantIcon/></span>
                        <span className="text-help"> Spiritual Consultation/ Spiritual Coaching</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/spiritual-gifts-assessment/introduction">
                        <span className="icon-help"> <BsFillClipboardDataFill/></span>
                        <span className="text-help"> Spiritual Gift Assessment</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/about-us/summary">
                        <span className="icon-help aboutus-icon">< BsFillPeopleFill/></span>
                        <span className="text-help"> About Us</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/about/contact">
                        <span className="icon-help"><ContactPageIcon/></span>
                        <span className="text-help">  Contact Us</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/term-of-use">
                        <span className="icon-help"><BsFillFileEarmarkMedicalFill/></span>
                        <span className="text-help">  Terms of Use</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown.Item id="drop-item" href="/privacy-policy">
                        <span className="icon-help"><BsFillFileEarmarkLockFill/></span>
                        <span className="text-help">  Privacy Policy</span>
                    </Dropdown.Item>
                    <NavDropdown.Divider/>

                    <Dropdown id="dropdown2">
                        <Dropdown.Toggle id="dropdown">
                            <span className="icon-help"><RecordVoiceOverIcon/></span>
                            <span className="text-help"> Voice Choice (for Scripture reading)</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <VoicePopover/>
                        </Dropdown.Menu>
                    </Dropdown>

                </NavDropdown>
            </div>
        );
    }
}

