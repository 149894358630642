import React from 'react';
import {withRouter} from 'react-router-dom';
import './register-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {Link} from 'react-router-dom';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {AccountService} from '../../../services/account.service';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CustomModal from '../../common/reusable/custom-modal/custom-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const initialValues = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    recaptcha: '',
    receiveNotification: true,
};

/* local: */
// const google_recaptcha_api_key = "6LcpkKsUAAAAADxV8pSA8Pklfkl-UUxUV3xOuqKE";
const google_recaptcha_api_key = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            errorFromBackend: null,
            isShowModal: false,
            showNewPassword: false,
            showConfirmPassword: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOKButton = this.handleOKButton.bind(this);
        this.handleShowNewPasswordToggle = this.handleShowNewPasswordToggle.bind(this)
        this.handleShowConfirmPasswordToggle = this.handleShowConfirmPasswordToggle.bind(this)
    }

    handleShowNewPasswordToggle(event) {
        this.setState((prevState) => ({
            showNewPassword: !prevState.showNewPassword,
        }));
    }

    handleShowConfirmPasswordToggle(event) {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        phoneNumber: Yup.string()
            .notRequired(),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        recaptcha: Yup.string('The reCAPTCHA was invalid.').required(
            'The reCAPTCHA was invalid.'
        ),
        receiveNotification: Yup.bool(),
    });

    showErrorMessageFromBackend() {
        const {errorFromBackend} = this.state;
        if (errorFromBackend) {
            const errorMessage = this.state.errorFromBackend;
            return <div className="error-yellow">{errorMessage}</div>;
        }
    }

    async onSubmit(fields, {setStatus, setSubmitting, setErrors}) {
        this.setState({
            errorFromBackend: null,
        });
        setStatus();
        fields["language"] = "en";
        await AccountService.register(fields)
            .then((res) => {
                setSubmitting(false);

                if (res.status === 400) {
                    if (res.data?.email) {
                        setErrors({email: res.data?.email});
                        return;
                    }
                }

                // Backend - internal server error occured.
                if (res.status === 500) {
                    this.setState({
                        errorFromBackend:
                            'We are sorry. An server error occurred. Please try again or contact the administrator.',
                    });
                    return;
                }

                // On Registration Successful
                this.handleShowModal();
            })
            .catch((error) => {
                setSubmitting(false);
            });
    }

    handleCloseModal() {
        this.setState({
            isShowModal: false,
        });
    }

    handleShowModal() {
        this.setState({
            isShowModal: true,
        });
    }

    handleOKButton() {
        this.props.history.push({
            pathname: `/account/login`,
            state: {},
        });
    }

    render() {
        const {errorFromBackend, isShowModal} = this.state;
        return (
            <div className='register-page'>
                <Helmet>
                    <title> Sign Up | ScriptureCast</title>
                </Helmet>
                <>
                    <CustomModal
                        isShowModal={isShowModal}
                        modalHeading={'Registration Successful'}
                        modalBody={
                            'Registration successful, please check your email for verification instructions.'
                        }
                        buttonName={'OK'}
                        handleCloseModal={this.handleCloseModal}
                        handleOKButton={this.handleOKButton}
                    ></CustomModal>
                </>

                <div className="register-form box-style1">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.onSubmit}
                    >
                        {({errors, touched, isSubmitting, setFieldValue}) => (
                            <Form>
                                <div className="font-size-large text-center">Create a new account</div>
                                <div className="register-form-body">
                                    {/* name */}
                                    <div className='name-section'>
                                        <div className='title'>
                                            <label>Title</label>
                                            <Field name="title" as="select"
                                                   className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')}>
                                                <option value=""></option>
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Miss">Miss</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage name="title" component="div" className="error-yellow"/>
                                        </div>
                                        <div className='name'>
                                            <div>
                                                <label>First Name</label>
                                                <Field name="firstName" type="text"
                                                       className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="firstName" component="div"
                                                              className="error-yellow"/>
                                            </div>
                                            <div>
                                                <label>Last Name</label>
                                                <Field name="lastName" type="text"
                                                       className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="lastName" component="div" className="error-yellow"/>
                                            </div>
                                        </div>
                                    </div>


                                    {/* phone - email */}
                                    <div className='email-section'>
                                        <div>
                                            <label>Email</label>
                                            <Field name="email" type="text"
                                                   className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="email" className="error-yellow"/>
                                        </div>
                                        <div>
                                            <label>Mobile number (Optional)</label>
                                            <PhoneInput ountry={'us'} placeholder="Type your phone here"
                                                        onChange={(value, data, event, formattedValue) => {
                                                            setFieldValue('phoneNumber', formattedValue);
                                                        }}/>
                                        </div>

                                    </div>

                                    {/* password */}
                                    <div className='password-section'>
                                        <div>
                                            <label>Password</label>
                                            <div className='password-input'>
                                                <Field name="password"
                                                       type={this.state.showNewPassword ? 'text' : 'password'}
                                                       className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>

                                                <button type="button" className="show-password-icon2"
                                                        onClick={this.handleShowNewPasswordToggle}>
                                                    <FontAwesomeIcon
                                                        icon={this.state.showNewPassword ? faEyeSlash : faEye}/>
                                                </button>

                                            </div>
                                            <ErrorMessage name="password" component="div" className="error-yellow"/>
                                        </div>
                                        <div>
                                            <label>Confirm Password</label>
                                            <div className='password-input'>
                                                <Field name="confirmPassword"
                                                       type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                       className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
                                                <button type="button" className="show-password-icon2"
                                                        onClick={this.handleShowConfirmPasswordToggle}>
                                                    <FontAwesomeIcon
                                                        icon={this.state.showConfirmPassword ? faEyeSlash : faEye}/>
                                                </button>
                                            </div>
                                            <ErrorMessage name="confirmPassword" component="div"
                                                          className="error-yellow"/>
                                        </div>
                                    </div>

                                    {/* agree */}
                                    <div className="flex flex-row gap-2">
                                        <Field type="checkbox" name="receiveNotification" id="receiveNotification"/>
                                        <label htmlFor="receiveNotification" className="form-check-label"><i>Yes, I
                                            would like to receive notifications on special events.</i></label>
                                    </div>

                                    {/* button */}
                                    <div className='button-section'>
                                        <ReCAPTCHA style={{display: 'inline-block'}} theme="light" size="normal"
                                                   sitekey={google_recaptcha_api_key} onChange={(response) => {
                                            if (response === null) {
                                                setFieldValue('recaptcha', '');
                                            } else {
                                                setFieldValue('recaptcha', response);
                                            }
                                        }}
                                        />
                                        {errors.recaptcha && touched.recaptcha && (
                                            <div className="error-yellow">{errors.recaptcha}</div>)}

                                        <button type="submit" disabled={isSubmitting} className="button button-style1">
                                            {isSubmitting && (
                                                <span className="spinner-border spinner-border-sm mr-1"></span>)}
                                            Sign Up
                                        </button>
                                        <div className="agree-signup-container font-size-small">
                                            By clicking 'Sign Up' you agree to our&nbsp;<Link className='link-style1'
                                                                                              to='/term-of-use'>Terms of
                                            Service</Link>&nbsp;and &nbsp;<Link className='link-style1'
                                                                                to="/privacy-policy">Privacy
                                            Policy</Link>
                                        </div>
                                        <div>Already have an account?&nbsp;<Link className='link-style1' to="login">Sign
                                            In.</Link></div>

                                    </div>

                                    {errorFromBackend && this.showErrorMessageFromBackend()}

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(Register)
);
