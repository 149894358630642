import Container from '@mui/material/Container'
import React from 'react';
import {withRouter} from 'react-router-dom';
import CategoryService from '../../services/categories.service.js';
import {__debug, remove_special_char} from '../../utils.js';
import SubjectsService from '../../services/subjects.service.js';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader.js"
import {connect} from 'react-redux';
import {BsArrowRight} from "react-icons/bs";
import './bible.css'
import {replaceWithHighlight, formatLink, addParamsToURL} from '../../utils.js'
import {LinkHoverUnderline} from '../common/reusable/link-hover-underline/link-hover-underline.jsx';
import {emitter} from "../common/function/event-emitter/event-emitter";
import lang_dict from '../../lang/lang_dict.json';


class CategoryBySubjectList extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        const lang = localStorage.getItem('selectedLanguage') || 'en';
        const searchParams = new URLSearchParams(window.location.search)
        let previous_category = formatLink(searchParams.get('category') || '');
        const keyword = searchParams.get('search_text')?.toUpperCase() || ''
        this.state = {
            categoryBySubjectList: [],
            index: props.location.state?.index,
            subject_info: {},
            subjectId: '',
            keyword,
            previous_category,
            subjectUrl : '',
            lang
        };
        this.retrieveCategoryBySubject = this.retrieveCategoryBySubject.bind(this);
    }

    async componentDidMount() {
        let is_subject_valid = false;
        let subjectUrl = '/subject-list'
        if (this.props.match && this.props.match.params) {
            let element_list = this.props.match.params.subjectId.split("-");
            let subjectId = element_list[element_list.length - 1];
            let subject_prefix = element_list.slice(0, element_list.length - 1).join("-");
            const response = await SubjectsService.getSubjectById(subjectId);
            const found_subject = response.data?.data;
            if (found_subject) {
                subjectUrl += `?subject=${formatLink(found_subject["subject"])}`
                if (subject_prefix === remove_special_char(found_subject["subject"])) {
                    is_subject_valid = true
                    this.setState({
                        subjectId: subjectId,
                        subject_info: found_subject,
                        subjectPath: subject_prefix + "-" + subjectId
                    });
                    this.retrieveCategoryBySubject(subjectId);
                }
            }
        }
        this.setState({subjectUrl})
        if (!is_subject_valid) {
            this.props.history.push({
                pathname: `/error`,
            });
        }
        emitter.on('languageChange', this.handleLanguageChange);
        this.props.STOP_LOADING();
    }

    onClick = (index, currentCategory) => {
        addParamsToURL(this,{category : currentCategory})
        this.props.history.push({
            pathname: `/bible/${this.state.subjectPath}/${remove_special_char(currentCategory)}-${index}`
        });
    };

    retrieveCategoryBySubject(subjectId) {
        CategoryService.getAllCategoriesBySubject(subjectId)
            .then((response) => {
                this.setState({
                    categoryBySubjectList: response.data?.data,
                });
                __debug(response.data?.data, 'p');
                if (this.state.previous_category) {
                   setTimeout(()=>this.scrollToCategory(this.state.previous_category),500) 
                }
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }

      scrollToCategory = (category) => {
        const targetElement = document.getElementById(`link-${formatLink(category)}`)
        if (targetElement) {
            targetElement.classList.add('clicked')
            targetElement.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
        } else {
            console.log(`Element with data-category='${category}' not found`);
        }
    }

    handleLanguageChange = (lang) => {
        this.setState({ lang });
    }

    render() {
        const {categoryBySubjectList, subject_info,keyword,subjectUrl, lang} = this.state;
        const subject_lang = `subject_${lang}`;
        const cat_lang = `category_${lang}`;
        return (
            <Container>
                <Helmet>
                    <title>Categories Related To Subject | ScriptureCast®</title>
                </Helmet>

                <div className='category-list-content bible-content'>
                    <div className='left-section'>
                        {keyword && <div>Keyword: <strong>{keyword}</strong></div>} 
                        <div className='left-item'>
                            <div className="sc-title">{lang_dict["Subject"][lang]}</div>
                            <div className="sc-content">  <LinkHoverUnderline
                                    url={subjectUrl}
                                    linkText={subject_info[subject_lang]}
                                /></div>
                        </div>
                        <div className='left-item'>
                            <div className="sc-title">{lang_dict["Category"][lang]}</div>
                            <div className="sc-content">{lang_dict["Please Select to view related topics"][lang]}<BsArrowRight/></div>
                        </div>
                    </div>
                    <div className='right-section'>
                        <div className="bible-link-menu" id='category-list'>
                            {categoryBySubjectList &&
                                categoryBySubjectList.map((item, index) => (
                                    <div
                                        key={item['id']}
                                        id={`link-${formatLink(item['category'])}`}
                                        className="scripture-link"
                                        onClick={() => {
                                            this.onClick(item['id'], item['category']);
                                        }}
                                    >{keyword? replaceWithHighlight(item[cat_lang],keyword): item[cat_lang]}
                                       
                                    </div>
                                ))}

                        </div>
                    </div>

                </div>

            </Container>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(CategoryBySubjectList));